.identity-settings {
  .mantine-Text-root {
    max-width: 800px;
  }

  &__missing-verification-info {
    color: var(--mantine-color-red-7);

    a {
      color: inherit;
      font-weight: 500;
      text-decoration: underline !important;
    }
  }
}

.success-banner {
  align-items: center;
  background-color: rgb(75 181 67 / 10%);
  border-radius: 8px;
  display: flex;
  gap: 0.5rem;
  margin-top: 20px;
  padding: 16px 8px;
}

.error-banner {
  align-items: center;
  background: rgb(255 148 148 / 40%);
  border-radius: 8px;
  display: flex;
  gap: 0.5rem;
  margin-top: 20px;
  padding: 16px 8px;
}

.personna-frame > iframe {
  height: 500px;
  width: 100%;
}
