.ag-theme-alpine,
.ag-theme-alpine-dark,
.ag-theme-alpine-auto-dark {
  /* disable all borders */
  --ag-borders: none;

  /* then add back a border between rows */
  --ag-row-border-width: 0.5px;
  --ag-row-border-color: lightgray;
  --ag-odd-row-background-color: white;
  --ag-row-height: calc(var(--ag-grid-size) * 8);
  --ag-header-height: calc(var(--ag-grid-size) * 9);

  /* and between columns */
}
