@import './styles/utilities/_mixins.scss';
@import './styles/variables/_colors.scss';
@import './styles/variables/_spacing.scss';
@import './styles/base/_typography.scss';

.mantine-TextInput {
  &-label {
    font-weight: 600;
    margin-bottom: 6px;
  }

  &-input {
    transition: box-shadow 300ms ease-out;

    &:focus {
      border: 0;
      box-shadow: var(--mantine-color-primary-2) 0 0 0 4px;
    }
  }
}

.font-bold {
  font-weight: 600;
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.alert-banner-card {
  background: rgb(255 148 148 / 40%);

  .alert-banner {
    align-items: center;
    display: flex;
    gap: 0.5rem;
  }
}

#tf-root {
  display: flex;
  flex-direction: column;
}

.mantine-Container-root {
  margin: 90px 50px 4px;
  max-width: 100%;

  @media screen and (max-width: 480px) {
    margin: 60px 20px;
    padding: 0;
  }
}

.notificationbar-info {
  align-items: center;
  color: black;
  display: flex;
  height: 2rem;
}

.loader-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full viewport height */
  justify-content: center;
  text-align: center;
}
