.mantine-Stepper-content {
  padding: 12px;
}

.mantine-Autocomplete-root {
  label {
    font-weight: 600;
    margin-bottom: 6px;
  }
}

.stepper-container {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  height: 100%; /* Full viewport height */
}

.stepper-header {
  flex-shrink: 0; /* Prevent shrinking */
}

.step-content {
  flex-grow: 1; /* Allow the content to grow and take available space */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 20px; /* Add padding for better spacing */
}

.stepper-footer {
  background-color: white; /* Keep the footer background white */
  border-top: 1px solid #e0e0e0; /* Optional styling */
  flex-shrink: 0; /* Prevent shrinking */
  padding: 10px 0;
}

.ubo-box {
  border: 1px solid;
  margin-bottom: 12px;
  padding: 20px;
}

.entity-created-container {
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgb(0 0 0 / 10%);
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  width: 70%;

  .check-icon-container {
    align-items: center;
    background-color: #28a745;
    border-radius: 50%;
    display: flex;
    height: 60px;
    justify-content: center;
    margin: 0 auto 20px;
    width: 60px;

    .check-icon {
      color: white;
      font-size: 2rem;
    }
  }

  .success-message {
    color: #333;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .email-notification {
    background-color: #f0f8ff;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 14px;
    height: auto;
    line-height: 1.5;
    margin: 15px 0;
    padding: 10px;
  }

  .ubo-list {
    list-style-type: disc;
    margin-bottom: 20px;
    padding-left: 20px;
    text-align: left;

    li {
      color: #555;
      font-size: 14px;
    }
  }

  .ok-button {
    margin-top: 20px;
  }
}
