.radio-card {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
  padding: 0 !important;
  // border-radius: 4px;
  // border: 1px solid transparent;
  // box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s, background-color 0.3s;

  &:hover {
    //   background-color: #f0f9ff;
    box-shadow: 0 4px 12px rgb(0 0 0 / 10%);
  }

  &.selected {
    border-color: #5609aa;
  }

  .radio-card-group {
    align-items: stretch;
    width: 100%;

    .mantine-Stack-root {
      width: 100%;
    }

    .status-box {
      justify-content: space-between;
    }
  }

  .radio-box {
    align-items: center;
    background-color: #f1f3f5;
    border-right: 1px solid #ced4da;
    cursor: pointer;
    display: flex;
    justify-content: center;
    min-height: 79px;
    min-width: 40px;

    &:hover {
      background-color: rgb(245 229 255 / 100%);
    }

    input {
      cursor: pointer;
    }
  }

  .radio-box.selected {
    background-color: rgb(245 229 255 / 100%);
    border-right: #5609aa;
  }

  .radio-check {
    background-color: white;
    border-radius: 50%;
    height: 16px;
    width: 16px;
  }

  .status {
    background-color: #ffe066;
    border-radius: 4px;
    color: black;
    cursor: pointer;
    font-size: 12px;
    padding: 3px 8px;
  }

  .status.disabled {
    cursor: not-allowed;
  }

  .title {
    font-weight: 500;
  }

  .description {
    color: #6c757d;
    font-size: 14px;
  }
}
