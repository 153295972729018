.company-card {
  display: flex;
  flex-direction: column;
  height: 100%;

  &:hover {
    box-shadow: var(--mantine-shadow-sm);
  }

  &__cover-photo {
    img {
      object-fit: cover;
    }
  }

  &__logo {
    border: 4px solid var(--mantine-color-white);
    box-shadow: var(--mantine-shadow-xs);
    box-sizing: content-box;
  }

  .company-details {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
}

.deal-status {
  background-color: #6741d9;
  color: white;
}

.companies-container {
  // justify-content: space-between;
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.flex-1 {
  flex: 1;
}
