.header {
  background-color: var(--mantine-color-body);
  border-bottom: 1px solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
  .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8em 1em;
    .logo {
      height: 45px;
    }
  }
}
