.rewardCardBox {
  align-items: center;
  background-color: var(--mantine-color-primary-0); /* Set the background color */
  border-radius: 8px; /* Optional: Adds rounded corners */
  color: var(--mantine-color-primary-6);
  display: flex; /* Enables flexbox */
  height: 20px; /* Set a fixed height */
  justify-content: center; /* Centers horizontally */
  padding: 20px; /* Optional: Adds some padding for spacing */
}

.reward-card-wallet {
  gap: var(--mantine-spacing-lg); // Default spacing for larger screens
}

.how-it-works-box {
  gap: 1rem;
}

.faq-box {
  margin: 0 auto;
  padding: 2rem;
}

@media (max-width: 768px) {
  .how-it-works-box {
    flex-direction: column;

    div {
      max-width: 100%;
    }
  }

  .reward-card-wallet {
    flex-direction: column;
    width: 100%;
  }

  .faq-box {
    margin-top: 20px;
    padding: 0;
  }
}

// .center-aligned-header {
//     text-align: center;
// }

// .center-aligned-cell {
//     text-align: center;
// }

// .ag-row .ag-cell {
//   display: flex;
//   justify-content: center; /* align horizontal */
//   align-items: center;
// }
