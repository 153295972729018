.investors-page {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.5rem;

  .icon {
    height: 2rem;
    width: 2rem;
  }

  .table-wrapper {
    border: 1px solid #d8b5fe;
    border-radius: 8px;
    min-width: 800px;
    padding: 12px;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    th {
      padding: 14px 8px;
      text-align: center;
    }

    td {
      padding: 8px;
      text-align: center;
    }
  }

  h4 {
    margin-bottom: 0;
  }
}

.apexcharts-bar-series.apexcharts-plot-series .apexcharts-series path {
  clip-path: inset(0% 0% -11% 0% round 8px);
}

.dashboard-card {
  align-items: flex-start;
  display: flex;
  gap: 1.5rem;
  padding-top: 2rem;
  width: 20rem;
  //   background-color: red;
  .subtitle {
    font-size: 0.9rem;
    margin: 0;
  }

  .title {
    font-size: 1rem;
    margin: 0;
  }
}

.investors-dashboard-container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: space-between;
}

.investors-chart-container {
  align-items: center;
  display: flex;
  gap: 0.5rem;
  justify-content: space-around;
}

.investors-table-container {
  overflow-x: auto;
  width: 100%;
}

// media query for tablets and medium screens
@media only screen and (max-width: 855px) {
  .investors-dashboard-container,
  .investors-chart-container {
    flex: 50%;
    justify-content: center;
  }
}

@media only screen and (max-width: 600px) {
  .investors-dashboard-container,
  .investors-chart-container {
    align-items: stretch;
    flex-direction: column;
    // width: 100vw;
  }

  .dashboard-card {
    width: auto;
  }

  .investors-table-container {
    .td {
      font-size: 0.5rem;
    }
  }
}

.dashboard-chart {
  border: solid 0.5px rgba($color: #242b2b, $alpha: 20%);
  border-radius: 8px;
  box-sizing: border-box;
  flex: 1;
  height: 100%;
  min-height: 0;
  overflow: auto;
  padding: 0;
  padding: 2rem;
  width: 100%;

  .title {
    font-size: 1rem;
    margin: 0;
  }
}

.chart-wrapper {
  display: block;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.investors-loader-wrapper {
  align-items: center;
  display: flex;
  height: 60vh;
  justify-content: center;
}

.status-drop-down {
  select {
    border: 1px solid lightgrey;
    border-radius: 4px;
    height: 30px;
    width: 100%;
  }
}
