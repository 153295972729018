.email-notification {
  background-color: #f0f8ff;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
  height: auto;
  line-height: 1.5;
  margin: 15px 0;
  padding: 10px;
}

.footer-button {
  align-items: center;
  border-top: 1px solid;
  display: flex;
  justify-content: center;
  margin-top: 20px;

  button {
    margin-top: 20px;
  }
}
