.details-spacing-contatiner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.highlighted-anchor {
  color: #0070f3;
  text-decoration: underline !important;
}

.wired-commitment-message {
  text-align: center;
  white-space: normal;
  word-wrap: break-word;
}

.warning-message-box {
  background-color: var(--mantine-color-red-1);
  color: var(--mantine-color-red-7);
  padding: 20px;
}

.new-investment-message {
  border: 1px solid lightgrey;
  border-radius: 6px;
  padding: 20px;
}

.error-box {
  border: 1px solid #fa5252;
  border-radius: 4px;
  padding: 20px;
  transition: padding 0.3s ease, border 0.3s ease, background-color 0.3s ease;
}

.normal-box {
  border: 1px solid transparent;
  transition: padding 0.3s ease, border 0.3s ease, background-color 0.3s ease;
}
