$page-height: calc(80vh - 110px);

.not-found {
  position: relative;

  &__image {
    max-width: 800px;
    min-width: 540px;
    opacity: 0.75;
    width: 100%;

    path {
      fill: var(--mantine-color-gray-3);
    }
  }

  &__image-container {
    display: flex;
    justify-content: center;
    left: 0;
    margin: 0;
    min-height: $page-height;
    overflow: hidden;
    position: absolute;
    width: 100%;
    z-index: -1;
  }

  &__content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: $page-height;
    text-align: center;
  }
}
